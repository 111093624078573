<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap v-if="appUser.usertype == 1" justify-center>
      <v-flex xs12 v-if="countData" align-center>
        <v-layout wrap pa-4 justify-start>
          <v-flex xs12 sm6 md8 align-self-center>
            <span class="nsbold" style="font-size:20px">Present Data</span>
          </v-flex>
            <v-flex xs12 sm5 md3 pr-1>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="Date"
                    outlined
                    readonly
                    dense 
                    hide-details
                    class="rounded-xl"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
        </v-layout>
        <v-layout wrap v-if="countData.length > 0">
        
          <v-flex v-for="(item, i) in countData" :key="i" xs12 pa-4>
            <v-layout wrap>
              <v-flex xs12 v-if="item._id">
                <span>{{item._id.hostelName}} : (Total Students : {{item.mainTotal}}, Total Present : {{item.totalPresent}}, Total Absent : {{item.totalAbsent}})</span>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm6 md3 v-for="(item2, i) in item.attendanceLog" :key="i" pa-4>
                 <v-card height="150px" class="gradcard">
              <v-layout fill-height align-content-center wrap white--text>
                <v-flex v-if="item2._id"
                  xs12
                  text-center
                  class="nsregular"
                  style="font-size: 15px"
                >
                  <v-icon
                    class="pr-1 pb-1"
                    color="white"
                    style="font-size: 25px"
                    >mdi-account-school</v-icon
                  >
                  {{ item2._id.Block}}<br/>(Warden : {{item2._id.hostelWarden.name}})
                </v-flex>
                <v-flex xs12 px-4><v-divider dark ></v-divider></v-flex>
          
                 <!-- <v-flex text-center  
                  xs12 px-4
                  text-left v-if=" item2.numberOfStudentsPresent==0 && item2.numberOfStudentsAbsent==0"
                  class="nsregular"
                  
                >
                <span style="font-size: 15px">
                 Total students present in hostel: {{ item2.totalStudents }}</span>
                 <br/>
                 <span style="font-size: 13px">( No attendance marked today )</span>
               

                </v-flex> -->
                      <v-flex
                  xs12 px-4
                  text-left 
                  class="nsregular"
                  style="font-size: 15px"
                >
                 Total students present in hostel: {{ item2.totalStudents }}<br/>
                </v-flex>
                 <v-flex
                  xs12 px-4
                  text-left 
                  class="nsregular"
                  style="font-size: 15px"
                >
                <v-layout wrap>
                  <v-flex xs6 pr-2>
                    Present : {{ item2.numberOfStudentsPresent }}<br/>
                 Absent : {{ item2.numberOfStudentsAbsent }}
                 
                  </v-flex>
                  <v-flex xs6>
                    Leave : {{ item2.numberOfStudentsLeave }}<br/>
                 Outpass : {{ item2.numberOfStudentsOutPass }}
                  </v-flex>
                </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
              </v-flex>
            </v-layout>
           
          </v-flex>
        </v-layout>
        <v-layout v-else justify-center>
          <v-flex xs10 pa-2 align-self-center>
            <span class="nsbold" style="font-size: 18px"
              >No present student marked today</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-else>
      <v-flex v-if="$route.query.session == 'null'" pt-5>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2 align-self-center>
              <span class="nsbold" style="font-size: 18px"
                >No present student marked today</span
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 lg11 pa-0 pa-sm-6 v-else>
        <v-card
          class="mx-auto elevation-2 pa-6"
          max-width="1088px"
          align="center"
          outlined
        >
          <v-layout wrap class="pb-4">
            <v-flex xs12 md5 lg8 text-md-left text-left>
              <span class="nsbold" style="font-size: 18px"
                >Present Student List</span
              >
            </v-flex>
            <v-flex xs12 md3 lg3>
              <v-text-field
                v-model="keyword"
                dense
                @keyup="getData()"
                class="rounded-xl"
                hide-details
                label="Search Name/Ad.No"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <!-- <v-flex xs12 md3 lg1 pt-1 pl-1>
              <v-btn
              @click="getData()"
              style="font-size: 16px; font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              class="rounded-xl"
              depressed
              
              >SEARCH</v-btn
            >
            </v-flex> -->
            <v-flex xs11 md3 lg1 text-md-right text-left align-self-center>
              <v-btn small plain>
                <v-icon
                  @click="$router.go(-1)"
                  title="Back"
                  style="cursor: pointer; color: #766bc0"
                  >mdi-arrow-left</v-icon
                >
              </v-btn></v-flex
            >
          </v-layout>
          <!--------------------------list------------------------>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="presentList">
              <v-layout wrap>
                <v-flex xs12 v-if="presentList.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admn.No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <!-- <th class="text-left">Division</th> -->
                          <th class="text-left">Profile</th>
                          <!-- <th class="text-left">View</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr>{{attendance}}</tr>  -->
                        <tr v-for="(item, i) in presentList" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>{{ item.studentid.admissionNo }}</td>

                          <td>{{ item.studentid.name }}</td>
                          <td>
                            <span v-if="item.studentid.studentclass">
                              {{ item.studentid.studentclass.className }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <!-- <td>
                            <span v-if="item.studentid.division">
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->

                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                              <v-icon style="cursor: pointer; color: #766bc0">
                                mdi-eye
                              </v-icon>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      keyword: null,
      msg: null,
      //    issueStatus : ["All","Approved", "Out", "Active", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      presentList: [],
      testid: null,
      test1:null,
      countData: [],
    };
  },
  beforeMount() {
    this.getMe()
    this.getData2()
    // if (this.fromDate) {
    //   var dt = new Date();

    //   dt.setDate(dt.getDate() - 30 + 1);
    //   this.fromDate = dt.toISOString().substr(0, 10);
    // }
    // if (this.from) {
    //   var dlt = new Date();

    //   dlt.setDate(dlt.getDate() - 30 + 1);
    //   this.fromDate = dlt.toISOString().substr(0, 10);
    // }

    // this.testData();
    //  console.log("usertype===",this.test1 )
    // if (this.test1 == 1 && !this.test1==0) {
    //   this.getData2();
    // } else if (this.test1 == 0 && !this.test1 == 1) {
    //   this.getData();
    // }
  },

  computed: {
    appUser() {
      return store.state.userData;
    },
  },
  watch: {
    //  if(appUser.usertype!=1){
    // page() {
    //   this.getData();
    // },
    fromDate() {
      // if (this.page > 1) this.page = 1;
      this.getData2();
    },
    // toDate() {
    //   if (this.page > 1) this.page = 1;
    //   this.getData();
    // },
    // keyword() {
    //   if (this.page > 1) this.page = 1;
    //   this.getData();
    // },
    //  }
  },
  methods: {
    getData() {
      this.appLoading = true;
      if (this.$route.query.id == "undefined") {
        this.testid = null;
      } else {
        this.testid = this.$route.query.id;
      }
      axios({
        method: "post",
        url: "/todays/present/student/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          blockId: this.testid,
          page: this.page,
          limit: 20,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.presentList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getData2() {
      this.appLoading = true;
      axios({
        method: "post",
        // url: "todays/all/hostel/presentcount",
//  url: "/todays/present/absent/count/hostelwise",
url: "/todays/present/absent/count/mainhostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        data:{
          date:this.fromDate
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.countData = response.data.presentdata;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
      getMe() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) { 
             this.test1= response.data.data.usertype;
              if (this.test1 == 1 && !this.test1==0) {
      this.getData2();
    } else if (this.test1 == 0 && !this.test1 == 1) {
      this.getData();
    }
            //  }
            // store.commit("loginUser",response.data.token);
            // store.commit("userData",response.data.user);
          }
        })
        .catch((err) => {
          console.log(err());
        });
      
            
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
<style scoped>
.gradcard {
  background: transparent
    linear-gradient(104deg, #afa2ef 0%, #766bc0 72%, #6f60d6 100%) 0% 0% !important;
}
</style>